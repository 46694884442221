var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-link', {
    attrs: {
      "to": "/ProfessorLista"
    }
  }, [_c('b-card', {
    staticClass: "h-100"
  }, [_c('div', {
    staticClass: "img-componente"
  }, [_c('img', {
    attrs: {
      "src": "/assets/images/componentes/1-prof.png"
    }
  }), _c('h4', [_vm._v("Professores")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-link', {
    attrs: {
      "to": "/EscolasLista"
    }
  }, [_c('b-card', {
    staticClass: "h-100",
    attrs: {
      "to": "/ProfessorLista"
    }
  }, [_c('div', {
    staticClass: "img-componente"
  }, [_c('img', {
    attrs: {
      "src": "/assets/images/componentes/2-escola.png"
    }
  }), _c('h4', [_vm._v("Escolas")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-link', {
    attrs: {
      "to": "/todosdesafios"
    }
  }, [_c('b-card', {
    staticClass: "h-100"
  }, [_c('div', {
    staticClass: "img-componente"
  }, [_c('img', {
    attrs: {
      "src": "/assets/images/componentes/4-desafios.png"
    }
  }), _c('h4', [_vm._v("Desafios")])])])], 1)], 1)], 1), _c('b-row')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }