<template>
  <div>
    <b-row class="mb-2">
      <b-col lg="3">
        <b-link to="/ProfessorLista">
          <b-card class="h-100">
            <div class="img-componente">
              <img src="/assets/images/componentes/1-prof.png" />
              <h4>Professores</h4>
            </div>
          </b-card>

        </b-link>
      </b-col>
      <b-col lg="3">
        <b-link to="/EscolasLista">
          <b-card class="h-100" to="/ProfessorLista">
            <div class="img-componente">
              <img src="/assets/images/componentes/2-escola.png" />
              <h4>Escolas</h4>
            </div>
          </b-card>
        </b-link>
      </b-col>
      <!-- <b-col lg="3">
        <b-card class="h-100">
          <div class="img-componente">
            <img src="/assets/images/componentes/3-jovem.png" />
            <h4>Jovens ACE</h4>
          </div>
        </b-card>
      </b-col> -->
      <b-col lg="3">

        <b-link to="/todosdesafios">
          <b-card class="h-100">
            <div class="img-componente">
              <img src="/assets/images/componentes/4-desafios.png" />
              <h4>Desafios</h4>
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
    <b-row>
      <!-- <b-col lg="3">
        <b-card class="h-100">
          <div class="img-componente">
            <img src="/assets/images/componentes/5-saude.png" />
            <h4>Profissionais de Saúde</h4>
          </div>
        </b-card>
      </b-col> -->
      <!-- <b-col lg="3">
        <b-card class="h-100">
          <div class="img-componente">
            <img src="/assets/images/componentes/6-ensino.png" />
            <h4>Profissionais de Ensino</h4>
          </div>
        </b-card>
      </b-col> -->
      <!-- <b-col lg="3">
        <b-card class="h-100">
          <div class="img-componente">
            <img src="/assets/images/componentes/7-pesquisador.png" />
            <h4>Pesquisadores</h4>
          </div>
        </b-card>
      </b-col> -->
      <!-- <b-col lg="3">
        <b-card class="h-100">
          <div class="img-componente">
            <img src="/assets/images/componentes/4-desafios.png" />
            <h4>Desafios</h4>
          </div>
        </b-card>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import PacienteCard from '@/components/cards/PacienteCard.vue'
import AgendaCard from '@/components/cards/AgendaCard.vue'
import medicalAppointmentService from '@/services/medicalAppointmentService'
import { BCard, BCardBody, BAvatar, BRow, BCol, BLink } from 'bootstrap-vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    PacienteCard,
    AgendaCard,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    BLink
  },
  data: () => ({
    agenda: [
      {
        id: 1,
        tipo: 'Teste Cognitivo',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
      {
        id: 1,
        tipo: 'Aplicação do Jogo',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
      {
        id: 1,
        tipo: 'Jogo Teste',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
      {
        id: 1,
        tipo: 'Devolutiva',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
    ],
    swiperDataRecents: [],
    swiperDataNexts: [],
    swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides: false,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        800: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    },
  }),
  methods: {
    // async getRecentsPatient() {
    //   this.swiperDataRecents =
    //     await medicalAppointmentService.getRecentsPatient()
    // },
    // async getNextsPatient() {
    //   this.swiperDataNexts = await medicalAppointmentService.getNextsPatient()
    // },
  },
  mounted() {
    // this.getRecentsPatient()

    // this.getNextsPatient()
  },
}
</script>
